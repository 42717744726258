export enum Routes {
  Home = '/',
  NotFound = '/404',
  PostAuth = '/post-auth',
  Questions = '/questions',
  SQLQuestions = '/sql-interview-questions',
  DatabaseDesignQuestions = '/database-interview-questions',
  StatisticsQuestions = '/statistics-interview-questions',
  MachineLearningQuestions = '/machine-learning-interview-questions',
  ProbabilityQuestions = '/probability-interview-questions',
  PythonQuestions = '/python-interview-questions',
  OfficeSQlQuestions = '/office-sql-questions',
  Question = '/questions/{questionSlug}',
  /* GeneralAssembly collaboration MVP pages. Currently not operating, left as an example for further collaborations */
  GeneralAssemblyQuestionsList = '/general-assembly/questions',
  GeneralAssemblyQuestion = '/general-assembly/questions/lesson-4-2-quiz',
  ProfilePerformance = '/profile',
  AccountSettings = '/account-settings',
  TermsOfService = '/terms-of-service',
  PrivacyPolicy = '/privacy-policy',
  Assets = '/assets',
  Checkout = '/checkout',
  Pricing = '/pricing',
  Premium = '/premium',
  SQLTutorial = '/sql-tutorial',
  Blog = '/blog',
  AboutAuthor = '/nick-singh',
}

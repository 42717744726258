import { Client as PGClient, QueryArrayResult } from 'pg';
import { IS_SERVER, POSTGRES_RUNTIME_CONNECTION_STRING, SupportedRuntime } from '@/constants';
import {
  ErrorMessageProcessorOptions,
  CommonProcessedRuntimeResponse,
  SelectingQuery,
  ForbiddenTablesSelectingQuery,
  MutatingQuery,
} from '@/types';
import { IRuntime } from './IRuntime';
import { getErrorLine } from '@/utils';

export class PGRuntime extends IRuntime<SupportedRuntime.Postgres> {
  protected runtime!: PGClient;

  async init(): Promise<void> {
    if (!IS_SERVER) return;
    this.runtime = new PGClient({
      connectionString: process.env.POSTGRES_RUNTIME_CONNECTION_STRING,
      ssl: Boolean(process.env.DB_PG_SSL),
    });
    await this.runtime.connect();
  }

  constructor() {
    super();
  }

  async execute(query: string): Promise<QueryArrayResult> {
    const queryResult = await this.runtime.query({
      text: query,
      rowMode: 'array',
    });
    return queryResult;
  }

  interpolateSystemRelationNamesIntoQuery(query: string, servedTables: string[], postfix: string | number): string {
    let processedQuery = query;

    servedTables.map(
      (tName) =>
        (processedQuery = processedQuery.replace(
          new RegExp(`\\b${tName}\\b`, 'gim'),
          `\"${tName}_${String(postfix)}\"`,
        )),
    );

    return processedQuery;
  }

  processErrorMessage(errorMsg?: string, options: ErrorMessageProcessorOptions = {}): string {
    if (!errorMsg) return '';

    let processedMessage: string = errorMsg;
    switch (true) {
      case ['relation', 'not exist'].every((keyStr) => errorMsg.includes(keyStr)) && !!options.servedTables:
        processedMessage = `[postgresql]: Specified relation(-s) does not exist. Available relation names: [${options.servedTables?.join(
          ', ',
        )}]`;
        break;
      case ['column', 'not exist'].every((keyStr) => errorMsg.includes(keyStr)):
        const singleQuotesErrorMsg =
          !!options.queryString && /=(\s+)?"/.test(options.queryString)
            ? '[postgresql]: An error may have been returned because you used double quotes for string text. Please use single quotes for string text. \n\n '
            : '';

        processedMessage = `${singleQuotesErrorMsg}${errorMsg}. \n FYI: in PostgreSQL all identifiers (including column names) that are not double-quoted are folded to lowercase. \n So if you're using any uppercased letters in the names and want to specify them in SQL-query, please double-quote them first.`;
        break;
      case /\w+_\d+/.test(errorMsg) && !!options.servedTables:
        let processedErrorMsg = errorMsg;

        options.servedTables?.map(
          (tName) => (processedErrorMsg = processedErrorMsg.replaceAll(new RegExp(`${tName}_\\d+`, 'gim'), tName)),
        );
        processedMessage = processedErrorMsg;
        break;
    }

    const populatedWithErrorPositionMessage = `${processedMessage}${
      options.interpolatedUserQuery && options.position
        ? ` (LINE: ${getErrorLine(options.interpolatedUserQuery, options.position)}`
        : ''
    })`;

    return populatedWithErrorPositionMessage;
  }

  processQueryResult(queryResult: QueryArrayResult<any[]>): CommonProcessedRuntimeResponse {
    const processedQueryResult = this.processQueryResultFields(queryResult).map((record) =>
      this.excludeSystemFields(record),
    );
    return processedQueryResult;
  }

  isMutatingQuery(maybeMutatingQuery: string): maybeMutatingQuery is MutatingQuery {
    return [
      'UPDATE',
      'ALTER',
      'REVOKE',
      'COMMIT',
      'SAVEPOINT',
      'GRANT',
      'REVOKE',
      'UPSERT',
      'DELETE',
      'CREATE',
      'DROP',
      'COPY',
      'INSERT',
    ].some((item) => maybeMutatingQuery.toUpperCase().includes(item));
  }

  isForbiddenTablesSelectingQuery(
    maybeForbiddenTablesSelectingQuery: string,
  ): maybeForbiddenTablesSelectingQuery is ForbiddenTablesSelectingQuery {
    return (
      ['information_schema.tables', 'tables'].some((table) =>
        maybeForbiddenTablesSelectingQuery.toLocaleLowerCase().includes(table),
      ) ||
      [/directus_[a-zA-Z0-9_]+/, /pg_[a-zA-Z0-9_]+/].some((template) =>
        template.test(maybeForbiddenTablesSelectingQuery.toLocaleLowerCase()),
      )
    );
  }

  isSelectingQuery(maybeSelectingQuery: string): maybeSelectingQuery is SelectingQuery {
    return maybeSelectingQuery.toUpperCase().includes('SELECT');
  }

  protected processQueryResultFields(queryResult: QueryArrayResult<any[]>): Record<string, any>[] {
    const processedQueryResultFields = (queryResult.fields || [])
      .map((field) => field.name)
      .map((fieldName, index, self) =>
        self.slice(0, index).includes(fieldName) ? `${fieldName}__dup${index}__` : fieldName,
      );

    return (queryResult.rows || []).map((row) =>
      Object.fromEntries(Object.entries(row).map(([key, value]) => [processedQueryResultFields[Number(key)], value])),
    );
  }

  protected excludeSystemFields(sourceObject: Record<string, any>): Record<string, any> {
    for (const key in sourceObject) {
      if (/\b(id|created_at|updated_at|date_created|date_updated)(__dup\d+__)?\b/.test(key)) delete sourceObject[key];
    }

    return sourceObject;
  }
}

export const pgRuntime = new PGRuntime();

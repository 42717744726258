import { Comment, Submission } from '@/types/pages/questions';
import { QuestionStatus } from '@/constants';

export const getQuestionStatus = (
  questionId: number,
  comments: Comment[],
  submissions: Submission[],
  isTextBased?: boolean,
): QuestionStatus | null => {
  if (!submissions?.length) {
    return null;
  }
  if (isTextBased && comments.some((el) => el.questionId === questionId)) {
    return QuestionStatus.Solved;
  }

  // set latest Submission's status as a Question status
  const selectedSubmission = submissions.find((el) => el.questionId === questionId);

  return selectedSubmission?.status || null;
};

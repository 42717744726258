import { Difficulty, QuestionStatus } from '@/constants';
import { Comment, Question } from '@/types/pages/profile';
import { Comment as CommentQuestionPage, Submission as SubmissionQuestionPage } from '@/types/pages/questions';
import { checkIsCodeQuestion } from '@/utils';
import { AchievementItem } from '@/types';
import { Submission, User } from '@/types/models';

const DIFFICULTIES_ORDER = [Difficulty.Easy, Difficulty.Medium, Difficulty.Hard];

const INITIAL_ACHIEVEMENT_ITEM: Pick<AchievementItem, 'codeQuestionsIds' | 'textBasedQuestionsIds'> = {
  codeQuestionsIds: [],
  textBasedQuestionsIds: [],
};

const getInitialQuestionsMapToDifficulty = (): Record<
  Difficulty,
  Pick<AchievementItem, 'codeQuestionsIds' | 'textBasedQuestionsIds'>
> => ({
  [Difficulty.Easy]: { ...INITIAL_ACHIEVEMENT_ITEM },
  [Difficulty.Medium]: { ...INITIAL_ACHIEVEMENT_ITEM },
  [Difficulty.Hard]: { ...INITIAL_ACHIEVEMENT_ITEM },
});

const getInitialState = (questions: Question[]): AchievementItem[] => {
  const questionsMapToDifficulty = getInitialQuestionsMapToDifficulty();

  questions.forEach((question) => {
    const item = questionsMapToDifficulty[question.difficulty];

    if (checkIsCodeQuestion(question.category)) {
      item.codeQuestionsIds = item.codeQuestionsIds.concat(question.id);
    } else {
      item.textBasedQuestionsIds = item.textBasedQuestionsIds.concat(question.id);
    }
  });

  return DIFFICULTIES_ORDER.map((difficulty) => ({
    difficulty,
    solvedAmount: 0,
    ...questionsMapToDifficulty[difficulty],
  }));
};

const calculateSolvedQuestions = (
  allAchievements: AchievementItem[],
  solvedCodeQuestionIds: number[],
  solvedTextBasedQuestionIds: number[],
) =>
  allAchievements.map((achievementSection) => {
    const newItem = {
      ...achievementSection,
      solvedAmount: 0,
    };

    solvedCodeQuestionIds.forEach((questionId) => {
      if (newItem.codeQuestionsIds.includes(questionId)) {
        newItem.solvedAmount++;
      }
    });

    solvedTextBasedQuestionIds.forEach((questionId) => {
      if (newItem.textBasedQuestionsIds.includes(questionId)) {
        newItem.solvedAmount++;
      }
    });

    return newItem;
  });

export const getAchievements = (
  user: User | null,
  questions: Question[],
  comments: Comment[] | CommentQuestionPage[],
  submissions: Submission[] | SubmissionQuestionPage[],
) => {
  const initialAchievements = getInitialState(questions);

  if (!user) {
    return initialAchievements;
  }

  const solvedTextBasedQuestionIds = Array.from(new Set(comments.map((comment) => comment.questionId)));
  const solvedCodeQuestionIds = Array.from(
    new Set(
      submissions
        .filter((submission) => submission.status === QuestionStatus.Solved)
        .map((submission) => submission.questionId),
    ),
  );

  return calculateSolvedQuestions(initialAchievements, solvedCodeQuestionIds, solvedTextBasedQuestionIds);
};

import {
  ApiEndpoint,
  AUTH0_RESET_PASSWORD_CONNECTION,
  AUTH0_RESET_PASSWORD_URL,
  NextApiEndpoint,
  SOMETHING_WENT_WRONG_MESSAGE,
} from '@/constants';
import { CamelToSnakeCaseNested, Nullable } from '@/types';
import { User, UTMParams } from '@/types/models';
import {
  ApiResponseData,
  CheckIsEmailVerifiedResponseSuccess,
  RequestOptions,
  VerifyEmailResponseSuccess,
} from '@/types/api';
import { convertKeysToCamelCase, getApiUrl, getNextApiUrl } from '@/utils';
import { ResetPasswordResponse } from '@/types/api/ResetPasswordResponse';
import { ResetPasswordResponseStatus } from '@/constants/pages/profile';
import { IncomingHttpHeaders } from 'http';
import Api from './Api';
import Logger from './Logger';

class UserService {
  public getCurrentUser = async <T extends Partial<User> = User>(
    options?: RequestOptions,
    headers?: IncomingHttpHeaders,
  ): Promise<T> => {
    const { data } = await Api.get<ApiResponseData<T>>(getNextApiUrl(NextApiEndpoint.GetCurrentUser, options), {
      headers,
    });

    return data.data;
  };

  public getByExternalId = async <T extends Partial<User> = User>(
    externalId: string,
    options?: RequestOptions,
  ): Promise<T> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options?.filter || {}),
        external_identifier: { _eq: externalId },
      },
    };

    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl('/users', finalOptions));

    return data.data[0];
  };

  public getByEmail = async <T extends Partial<User> = User>(email: string, options?: RequestOptions): Promise<T> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options?.filter || {}),
        email: { _eq: email },
      },
    };

    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl('/users', finalOptions));

    return data.data[0];
  };

  public getUsersByIds = async <T extends Partial<User> = User>(
    ids: string[],
    options: RequestOptions = {},
  ): Promise<T[]> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        id: { _in: ids },
      },
    };

    const { data } = await Api.get<CamelToSnakeCaseNested<T>>(getNextApiUrl(NextApiEndpoint.GetUsers, finalOptions));

    return convertKeysToCamelCase<T[]>(data);
  };

  public getUserById = async <T extends Partial<User> = User>(
    userId: string,
    options: RequestOptions = {},
  ): Promise<T> => {
    const { data } = await Api.get<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getApiUrl(ApiEndpoint.GetUserById.replace('{userId}', userId), options),
    );

    return convertKeysToCamelCase<T>(data.data);
  };

  public create = async <T extends Partial<User> = User>(
    clerkId: string,
    email: string,
    firstName: Nullable<string>,
    lastName: Nullable<string>,
    utmParams: Partial<UTMParams>,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<T>>(
      getApiUrl(ApiEndpoint.GetUsers), // change to camelCase and check
      {
        email,
        firstName,
        lastName,
        externalIdentifier: clerkId,
        provider: 'clerk',
        ...utmParams,
      },
    );

    return data.data;
  };

  public refreshLastAccessTime = async <T extends Partial<User> = User>(id: string): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<T>>(
      getApiUrl(ApiEndpoint.UpdateUserById.replace('{userId}', id)),
      {
        last_access: new Date(Date.now()),
      },
    );

    return data.data;
  };

  public getUsersByEmails = async <T extends Partial<User> = User>(
    emails: string[],
    options: RequestOptions = {},
  ): Promise<T[]> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        email: { _in: emails },
      },
    };

    const { data } = await Api.get<CamelToSnakeCaseNested<T>>(getNextApiUrl(NextApiEndpoint.GetUsers, finalOptions));

    return convertKeysToCamelCase<T[]>(data);
  };

  public getUsersByInviterCode = async <T extends Partial<User> = User>(
    referralCode: string,
    options: RequestOptions = {},
  ): Promise<T[]> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        inviter_code: { _eq: referralCode },
        email_verified: { _eq: true },
      },
    };

    const { data } = await Api.get<CamelToSnakeCaseNested<T>>(getNextApiUrl(NextApiEndpoint.GetUsers, finalOptions));

    return convertKeysToCamelCase<T[]>(data);
  };

  public updateUsersData = async <T extends Partial<User> = User>(
    usersData: CamelToSnakeCaseNested<Partial<User>>,
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
    headers?: IncomingHttpHeaders,
  ): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.UpdateUser, options)
        : getNextApiUrl(NextApiEndpoint.UpdateUserData, options),
      { ...usersData, user_id: userId },
      { headers },
    );

    return convertKeysToCamelCase<T>(data.data);
  };

  public updateUsersDataById = async <T extends Partial<User> = User>(
    userId: string,
    usersData: CamelToSnakeCaseNested<Partial<User>>,
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.UpdateUserById.replace('{userId}', userId), options)
        : getNextApiUrl(NextApiEndpoint.UpdateUserById, options),
      { ...usersData },
    );

    return convertKeysToCamelCase<T>(data.data);
  };

  public updateAvatar = async <T extends Partial<User> = User>(
    avatar: FormDataEntryValue[],
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute ? getApiUrl(ApiEndpoint.Files, options) : getNextApiUrl(NextApiEndpoint.Files, options),
      { usersData: avatar },
      {
        headers: {
          ['Content-Type']: 'multipart/form-data',
        },
      },
    );

    const userData = await this.updateUsersData({ avatar: data.data });
    return convertKeysToCamelCase<T>(userData);
  };

  public resetPassword = async (email: string): Promise<ResetPasswordResponse> => {
    try {
      const { data } = await Api.post<string>(AUTH0_RESET_PASSWORD_URL, {
        email,
        client_id: process.env.AUTH_AUTH0_CLIENT_ID,
        connection: AUTH0_RESET_PASSWORD_CONNECTION,
      });
      return { status: ResetPasswordResponseStatus.Success, message: data };
    } catch (e) {
      Logger.error(e);
      return {
        status: ResetPasswordResponseStatus.Error,
        message: SOMETHING_WENT_WRONG_MESSAGE,
      };
    }
  };

  public checkIsEmailVerified = async (): Promise<CheckIsEmailVerifiedResponseSuccess> => {
    const { data } = await Api.get<CheckIsEmailVerifiedResponseSuccess>(
      getNextApiUrl(NextApiEndpoint.CheckIsEmailVerified),
    );

    return data;
  };

  public resendVerificationToEmail = async (externalIdentifier: string): Promise<VerifyEmailResponseSuccess> => {
    const { data } = await Api.post<VerifyEmailResponseSuccess>(
      getNextApiUrl(NextApiEndpoint.ResendVerificationEmail),
      { externalIdentifier },
    );

    return data;
  };
}

const userService = new UserService();

export default userService;

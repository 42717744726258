import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { ApiResponseData, RequestOptions } from '@/types/api';
import { Vote } from '@/types/models';
import { getApiUrl, getNextApiUrl } from '@/utils';
import Api from './Api';

class VotesService {
  public getVotes = async <T extends Partial<Vote> = Vote>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetVotes, options)
        : getNextApiUrl(NextApiEndpoint.GetVotes, options),
    );

    return data.data;
  };

  public createVote = async <T extends Partial<Vote> = Vote>(
    { commentId, outcome }: { outcome: string; commentId: string },
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<T>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.CreateVote, options)
        : getNextApiUrl(NextApiEndpoint.CreateVote, options),
      {
        outcome,
        comment_id: commentId,
        user_id: userId,
      },
    );

    return data.data;
  };
}

export default new VotesService();

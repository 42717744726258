import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { getApiUrl } from '@/utils';
import { ApiEndpoint } from '@/constants';
import { Post } from '@/types/models';

class Blog {
  public getPosts = async <T extends Partial<Post>>(options?: RequestOptions): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetPosts, { ...options, limit: -1 }));

    return data.data;
  };

  public getPostBySlug = async <T extends Partial<Post> = Post>(
    slug: string,
    options: RequestOptions = {},
  ): Promise<T | null> => {
    const finalOptions: RequestOptions = {
      ...options,
      fields: ['*.*'],
      filter: {
        ...(options.filter || {}),
        slug: { _eq: slug },
      },
    };
    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetPosts, finalOptions));

    return data.data[0];
  };
}

export default new Blog();

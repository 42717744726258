import { ApiResponseData, RequestOptions } from '@/types/api';
import { ProductPlan } from '@/types/models';
import { getApiUrl, getNextApiUrl } from '@/utils';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import Api from '@/services/Api';

class ProductService {
  public getProductPlans = async <T extends ProductPlan>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetProducts, options)
        : getNextApiUrl(NextApiEndpoint.GetProducts, options),
    );

    return data.data;
  };
}

export default new ProductService();

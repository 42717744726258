import mixpanel, { Dict } from 'mixpanel-browser';
import { MixpanelEvent, MP_ID } from '@/constants';

class MixpanelTracker {
  private static _instance: MixpanelTracker;

  public static getInstance(): MixpanelTracker {
    if (MixpanelTracker._instance === null) {
      return (MixpanelTracker._instance = new MixpanelTracker());
    }

    return this._instance;
  }

  public constructor() {
    if (MixpanelTracker._instance) {
      throw new Error('Error: Instance creation of Mixpanel is not allow.');
    }

    mixpanel.init(MP_ID || '', { debug: false, ignore_dnt: true, track_pageview: true });
  }

  public identify(id: string) {
    mixpanel.identify(id);
  }

  public reset() {
    mixpanel.reset();
  }

  public track(event: MixpanelEvent | string, data?: Dict) {
    mixpanel.track(event, data);
  }
}

const mixpanelTracker = new MixpanelTracker();

export default mixpanelTracker;

import * as querystring from 'query-string';
import { ACTIVE_CAMPAIGN_API_URL, BE_URL, NEXT_BE_URL, SITE_URL } from '@/constants';
import { RequestOptions } from '@/types/api';

// TODO - remove this when proper pagination is implemented
// const UNLIMITED_ITEMS_VALUE = '-1';

const getGeneralApiUrl = (backEndUrl: string, path: string, options?: RequestOptions) => {
  const { filter, queryParams = {}, isAdminRequest, ...restOptions } = options || {};
  const query = querystring.stringify({
    // limit: UNLIMITED_ITEMS_VALUE,
    ...restOptions,
    filter: JSON.stringify(filter),
    ...queryParams,
  });
  const finalQuery = query ? `?${query}` : '';

  return `${backEndUrl}${path}${finalQuery}`;
};

export const getApiUrl = (path: string, options?: RequestOptions) => getGeneralApiUrl(BE_URL, path, options);

export const getNextApiUrl = (path: string, options?: RequestOptions) => getGeneralApiUrl(NEXT_BE_URL, path, options);

export const getSiteUrl = (path: string) => `${SITE_URL}${path}`;

export const getActiveCampaignUrl = (path: string) => `${ACTIVE_CAMPAIGN_API_URL}/${path}`;

import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { CamelToSnakeCaseNested } from '@/types';
import { convertKeysToCamelCase, getApiUrl, getNextApiUrl } from '@/utils';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { CustomerCard, Payment } from '@/types/models';

class PaymentService {
  public createPayment = async <T extends Payment>(
    value: CamelToSnakeCaseNested<Partial<Payment>>,
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.CreatePayment, options)
        : getNextApiUrl(NextApiEndpoint.CreatePayment, options),
      value,
    );

    return convertKeysToCamelCase<T>(data.data);
  };

  public getPayments = async <T extends Payment>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<CamelToSnakeCaseNested<T[]>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetPayments, options)
        : getNextApiUrl(NextApiEndpoint.GetPayments, options),
    );

    return convertKeysToCamelCase<T[]>(data.data);
  };

  public getUserCard = async <T extends CustomerCard>(): Promise<T> => {
    const { data } = await Api.get<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getNextApiUrl(NextApiEndpoint.GetUserCard),
    );

    return convertKeysToCamelCase<T>(data);
  };

  public updateUserPaymentMethod = async <T extends CustomerCard>(paymentMethodId: string): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getNextApiUrl(NextApiEndpoint.UpdatePaymentMethod),
      { paymentMethodId },
    );

    return convertKeysToCamelCase<T>(data);
  };
}

export default new PaymentService();

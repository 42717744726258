import {
  ACTIVE_CAMPAIGN_API_KEY,
  ActiveCampaignApiEndpoint,
  ActiveCampaignCustomFieldIds,
  ActiveCampaignTagsIds,
  NextApiEndpoint,
} from '@/constants';

import { Nullable, Undefinable } from '@/types';
import { ICreatedContactData, IGetByEmailContactData } from '@/types/api';
import { getActiveCampaignUrl, getNextApiUrl } from '@/utils';
import axios from 'axios';
import logger from './Logger';
import Api from './Api';

class ActiveCampaignService {
  async createContact(
    email: string,
    firstName: Nullable<string>,
    lastName: Nullable<string>,
  ): Promise<ICreatedContactData['contact'] | undefined> {
    try {
      const { data } = await axios.post<ICreatedContactData>(
        getActiveCampaignUrl(ActiveCampaignApiEndpoint.Contact),
        {
          contact: {
            email,
            firstName,
            lastName,
          },
        },
        {
          headers: {
            'Api-Token': ACTIVE_CAMPAIGN_API_KEY,
          },
        },
      );

      return data.contact;
    } catch (error) {
      logger.error(error);
    }
  }

  async addTag(contactId: string, tagId: ActiveCampaignTagsIds): Promise<void> {
    try {
      await axios.post(
        getActiveCampaignUrl(ActiveCampaignApiEndpoint.AddTag),
        {
          contactTag: {
            contact: contactId,
            tag: tagId,
          },
        },
        {
          headers: {
            'Api-Token': ACTIVE_CAMPAIGN_API_KEY,
          },
        },
      );
    } catch (error) {
      logger.error(error);
    }
  }

  async getByEmail(email: string): Promise<Undefinable<IGetByEmailContactData['contacts'][0]>> {
    try {
      const { data } = await axios.get<IGetByEmailContactData>(
        getActiveCampaignUrl(ActiveCampaignApiEndpoint.Contact),
        {
          params: {
            email,
          },
          headers: {
            'Api-Token': ACTIVE_CAMPAIGN_API_KEY,
          },
        },
      );
      return data.contacts[0];
    } catch (error) {
      logger.error(error);
    }
  }

  async setLastEngagementDateByEmail(email: string): Promise<void> {
    try {
      const contact = await this.getByEmail(email);
      await axios.post(
        getActiveCampaignUrl(ActiveCampaignApiEndpoint.UpdateCustomField),
        {
          fieldValue: {
            contact: contact?.id,
            field: ActiveCampaignCustomFieldIds.LastEngagementDate,
            value: new Date(Date.now()).toISOString(),
          },
        },
        {
          headers: {
            'Api-Token': ACTIVE_CAMPAIGN_API_KEY,
          },
        },
      );
    } catch (error) {
      logger.error(error);
    }
  }

  async setLastEngagementDateOnRunPythonSolution(): Promise<void> {
    try {
      await Api.post(getNextApiUrl(NextApiEndpoint.TrackOnRunPythonSolution));
    } catch (error) {
      logger.error(error);
    }
  }
}

const activeCampaignService = new ActiveCampaignService();

export default activeCampaignService;

import { DateTime } from 'luxon';
import { BillingPlanType } from '@/constants';

export const checkIsSubscriptionActive = (
  periodEndsAt: string | null | undefined,
  type: BillingPlanType | undefined,
): boolean => {
  if (type === BillingPlanType.Lifetime) {
    return true;
  }

  if (!periodEndsAt) {
    return false;
  }

  return DateTime.now().diff(DateTime.fromISO(periodEndsAt)).as('minutes') < 0;
};

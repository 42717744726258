import { EffectCallback, useEffect, useRef } from 'react';

export const useInfiniteScroll = (callback: EffectCallback) => {
  const sectionBottom = useRef(null);

  useEffect(() => {
    if (sectionBottom.current === null) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        callback();
      }
    });

    observer.observe(sectionBottom.current);

    return () => observer.disconnect();
  }, []); // TODO fix or leave a commment

  return sectionBottom;
};

import { NextApiEndpoint } from '@/constants';
import { getNextApiUrl } from '@/utils';
import Logger from '@/services/Logger';
import Api from './Api';

class TrackActivityService {
  async trackCheckout(): Promise<void> {
    try {
      await Api.post(getNextApiUrl(NextApiEndpoint.TrackCheckout));
    } catch (error: any) {
      Logger.error(error);
    }
  }
}

const trackActivityService = new TrackActivityService();

export default trackActivityService;

import { getErrorLine } from './helpers';

interface ErrorMessageProcessorOptions {
  servedTables?: string[];
  queryString?: string;
  position?: number;
  interpolatedUserQuery?: string;
}

export const errorMessageProcessor = (errorMsg?: string, options: ErrorMessageProcessorOptions = {}): string => {
  if (!errorMsg) return '';

  let processedMessage: string = errorMsg;
  switch (true) {
    case ['relation', 'not exist'].every((keyStr) => errorMsg.includes(keyStr)) && !!options.servedTables:
      processedMessage = `Specified relation(-s) does not exist. Available relation names: [${options.servedTables?.join(
        ', ',
      )}]`;
    case ['column', 'not exist'].every((keyStr) => errorMsg.includes(keyStr)):
      const singleQuotesErrorMsg =
        !!options.queryString && /=(\s+)?"/.test(options.queryString)
          ? 'An error may have been returned because you used double quotes for string text. Please use single quotes for string text. \n\n '
          : '';

      processedMessage = `${singleQuotesErrorMsg}${errorMsg}. \n FYI: in PostgreSQL all identifiers (including column names) that are not double-quoted are folded to lowercase. \n So if you're using any uppercased letters in the names and want to specify them in SQL-query, please double-quote them first.`;
    case /\w+_\d+/.test(errorMsg) && !!options.servedTables:
      let processedErrorMsg = errorMsg;

      options.servedTables?.map(
        (tName) => (processedErrorMsg = processedErrorMsg.replaceAll(new RegExp(`${tName}_\\d+`, 'gim'), tName)),
      );
      processedMessage = processedErrorMsg;
  }

  const populatedWithErrorPositionMessage = `${processedMessage}${
    options.interpolatedUserQuery && options.position
      ? ` (LINE: ${getErrorLine(options.interpolatedUserQuery, options.position)}`
      : ''
  })`;

  return populatedWithErrorPositionMessage;
};

import { parseUrl } from 'query-string';
import { REFERRAL_CODE_KEY } from '@/constants';

const checkReferralLink = (path: string): string | null => {
  const { query } = parseUrl(path);
  return (query[REFERRAL_CODE_KEY] as string) || null;
};

export const cacheReferralCode = (path: string): void => {
  const referralCode = checkReferralLink(path);

  if (!referralCode) {
    return;
  }
  const cachedCode = localStorage.getItem(REFERRAL_CODE_KEY);

  if (referralCode && cachedCode !== referralCode) {
    localStorage.setItem(REFERRAL_CODE_KEY, referralCode);
  }
};

import { ServerClient } from 'postmark';

import { NICKS_EMAIL, POSTMARK_API_KEY, PROJECT_NAME } from '@/constants';
import { MessageSendingResponse } from 'postmark/dist/client/models';
import Logger from '@/services/Logger';

class PostmarkService {
  async sendEmailWithTemplate(
    recipientEmail: string,
    templateId: number,
    templateModel: Record<string, string> = {},
  ): Promise<boolean | MessageSendingResponse> {
    if (!POSTMARK_API_KEY) {
      return false;
    }

    try {
      return await new ServerClient(POSTMARK_API_KEY).sendEmailWithTemplate({
        From: NICKS_EMAIL,
        TemplateId: templateId,
        To: recipientEmail,
        TemplateModel: {
          ...templateModel,
          product_name: PROJECT_NAME,
        },
      });
    } catch (error: any) {
      console.error('Failed to send an email via Postmark', error);
      Logger.error(error);

      return false;
    }
  }
}

const postmarkService = new PostmarkService();

export default postmarkService;

import { DateTime } from 'luxon';

const PERIOD_FROM_REGISTRATION_IN_MINUTES = 30;

export const checkIsUserRegisterInThirtyMinutes = (date: string | null): boolean => {
  if (!date) {
    return false;
  }
  const differenceInMinutes = DateTime.now().diff(DateTime.fromISO(date)).as('minutes');

  return differenceInMinutes < PERIOD_FROM_REGISTRATION_IN_MINUTES;
};

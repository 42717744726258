import { User } from '@/types/models';

export const getUserName = ({
  userName,
  firstName,
  lastName,
  email,
}: Pick<User, 'userName' | 'firstName' | 'lastName' | 'email'>): string => {
  if (userName) {
    return userName;
  }
  if (firstName || lastName) {
    return [firstName, lastName].filter(Boolean).join(' ').trim();
  }

  return email.split('@')[0];
};

import { sendGAEvent } from '@next/third-parties/google';
import { ApiResponseData } from '@/types/api';
import Api from '@/services/Api';
import { CamelToSnakeCaseNested } from '@/types';
import { GAnalyticsClientData, Subscription } from '@/types/models';
import { getNextApiUrl } from '@/utils';
import { NextApiEndpoint } from '@/constants';

export interface GAnalyticsTrackData {
  gAnalyticsClientData: Partial<GAnalyticsClientData>;
  subscription: Pick<Subscription, 'id' | 'subscriptionType' | 'amount'>;
  transaction: { id: string };
  utmParams?: string;
  userAgent: string;
  userId: string;
}

class GoogleAnalytics {
  public trackPurchase = async <T extends void>(values: GAnalyticsTrackData): Promise<void> => {
    Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(getNextApiUrl(NextApiEndpoint.TrackPurchase), values);
  };

  public getClientDataField = (field: string, cb: (value: string) => void): void => {
    sendGAEvent('get', process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || '', field, cb);
  };
}

export default new GoogleAnalytics();

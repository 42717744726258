import { Lesson } from '@/types/models';
import { SidebarLesson } from '@/types/pages/lesson';
import { LessonCategory } from '@/constants';

const categoryOrder = [LessonCategory.Basic, LessonCategory.Intermediate, LessonCategory.Advanced];

export const getNextLesson = (currentLesson: Lesson, lessons: SidebarLesson[]): SidebarLesson | null => {
  const nextLesson = lessons.find(
    (lessonItem) =>
      lessonItem.category === currentLesson.category && lessonItem.lessonNumber - currentLesson.lessonNumber === 1,
  );

  if (nextLesson) {
    return nextLesson;
  }

  const nextLessonCategory =
    categoryOrder[categoryOrder.findIndex((categoryItem) => currentLesson.category === categoryItem) + 1];

  return lessons.find((lessonItem) => lessonItem.category === nextLessonCategory) || null;
};

import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // field: {
  //   outline: 'none',
  //   _placeholder: {
  //     color: 'gray.500',
  //   },
  //   _readOnly: {
  //     cursor: 'pointer',
  //   },
  //   _disabled: {
  //     pointerEvents: 'none',
  //   },
  // },
});

const variantOutline = definePartsStyle(() => {
  const boxShadow = 'inset 0px 0.375rem 0.5rem rgba(0, 0, 0, 0.08)';

  return {
    field: {
      color: 'black.500',
      background: 'white',
      border: '1px',
      borderColor: 'gray.300',
      outline: 'none',
      pb: '2px',
      _placeholder: {
        color: 'black.300',
      },
      _disabled: {
        boxShadow: 'unset',
        bgColor: 'gray.100',
        color: 'gray.600',
        opacity: 1,
        borderColor: 'gray.300',
        pointerEvents: 'none',
      },
      _hover: {
        borderColor: 'gray.400',
      },
      _invalid: {
        borderColor: 'red.500',
      },
      _focusVisible: {
        boxShadow: 'unset',
        borderColor: 'gray.500',
      },
    },
  };
});

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: {
    outline: variantOutline,
  },
  defaultProps: {
    // variant: 'outline',
  },
});

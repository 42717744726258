import { ApiEndpoint, NextApiEndpoint, VoteOutcome } from '@/constants';
import { SnakeToCamelCaseNested } from '@/types';
import { ApiResponseData, RequestOptions } from '@/types/api';
import { Comment, NewCommentData, Vote } from '@/types/models';
import { VotesService } from '@/services/index';
import { getApiUrl, getNextApiUrl } from '@/utils';
import Api from './Api';

class CommentService {
  public getComments = async <T extends Partial<Comment> = Comment>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetComments, options)
        : getNextApiUrl(NextApiEndpoint.GetComments, options),
    );

    return data.data;
  };

  public getReplies = async <T extends Partial<Comment> = Comment>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetComments, options)
        : getNextApiUrl(NextApiEndpoint.GetComments, options),
    );

    return data.data;
  };

  public createComment = async <T extends Partial<Comment> = Comment>(
    commentData: SnakeToCamelCaseNested<NewCommentData>,
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<T>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.CreateComment, options)
        : getNextApiUrl(NextApiEndpoint.CreateComment, options),
      {
        userId,
        ...commentData,
      },
    );

    return data.data;
  };

  public createVotedComment = async <T extends Pick<Comment, 'id'> & Partial<Comment> = Comment>(
    { questionId, body, parentId }: SnakeToCamelCaseNested<NewCommentData>,
    options?: RequestOptions,
  ): Promise<{ comment: T; vote: Vote }> => {
    const comment = await this.createComment<T>({ questionId, body, parentId }, options);
    const vote = await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: comment.id });
    comment.upvotes = 1;

    return { comment, vote };
  };

  public deleteComment = async <T extends Partial<Comment> = Comment>(
    { commentId }: { commentId: string },
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<T>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.UpdateComment.replace('{commentId}', commentId), options)
        : getNextApiUrl(NextApiEndpoint.UpdateComment, options),
      { deleted_at: new Date(), comment_id: commentId, user_id: userId },
    );

    return data.data;
  };

  public updateComment = async <T extends Partial<Comment> = Comment>(
    {
      commentId,
      newCommentValue,
    }: {
      commentId: string;
      newCommentValue: string;
    },
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<T>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.UpdateComment.replace('{commentId}', commentId), options)
        : getNextApiUrl(NextApiEndpoint.UpdateComment, options),
      { body: newCommentValue, user_id: userId, ...(!isRequestFromAPIRoute && { comment_id: commentId }) },
    );

    return data.data;
  };
}

export default new CommentService();

import { ApiEndpoint, DirectusCollection } from '@/constants';
import { getApiUrl } from '@/utils';
import { ApiResponseData } from '@/types/api';
import { FieldMetadata } from '@/types/models';
import Api from './Api';

class DirectusService {
  public getCollectionFieldMetadata = async <K, T extends Partial<FieldMetadata<K>> = FieldMetadata<K>>(
    collection: DirectusCollection,
    field: string,
  ): Promise<T | undefined> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      getApiUrl(ApiEndpoint.GetCollectionMetadata.replace('{collection}', collection)),
    );

    return data.data.find((el) => el.field === field);
  };
}

export default new DirectusService();

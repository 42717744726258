import * as Sentry from '@sentry/nextjs';

class Logger {
  public error = (error: Error | unknown): void => {
    Sentry.captureException(error);
  };

  public info = (message: string): void => {
    Sentry.captureMessage(message, 'info');
  };
}

const logger = new Logger();

export default logger;

import * as Yup from 'yup';
import valid from 'card-validator';
import { BillingPlanType } from '@/constants';

export const IS_INVALID_TEMPLATE_STRING = ' is invalid';
export const CONTACT_WITH_ADMIN_TEMPLATE_STRING = 'Please contact with Admin.';

export const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email().required(`Email${IS_INVALID_TEMPLATE_STRING}`),
  holderName: Yup.string()
    .test('holderName', `Holder name${IS_INVALID_TEMPLATE_STRING}`, (value) => valid.cardholderName(value).isValid)
    .required(),
  country: Yup.string().required(`Country${IS_INVALID_TEMPLATE_STRING}`),
  postalCode: Yup.string()
    .test('postalCode', `Postal code${IS_INVALID_TEMPLATE_STRING}`, (value) => valid.postalCode(value).isValid)
    .required(),
  productId: Yup.string().required(`Product${IS_INVALID_TEMPLATE_STRING}. ${CONTACT_WITH_ADMIN_TEMPLATE_STRING}`),
  paymentMethodId: Yup.string().required(`Payment method${IS_INVALID_TEMPLATE_STRING}. Try to repeat later.`),
  subscriptionType: Yup.mixed<BillingPlanType>()
    .oneOf(Object.values(BillingPlanType), `Subscription type${IS_INVALID_TEMPLATE_STRING}`)
    .required(`Subscription type${IS_INVALID_TEMPLATE_STRING}`),
});

import { useRef, useEffect } from 'react';

export const useLatestValue = (stateValue: any) => {
  const valueRef = useRef(stateValue);

  useEffect(() => {
    valueRef.current = stateValue;
  }, [stateValue]);

  return valueRef;
};

import { createContext } from 'react';
import { User } from '@/types/models';

export interface UserContextValue {
  user: User | null;
  error: Error | null;
  isAuthInitialized: boolean;
  isLoading: boolean;
  login: (redirect?: string) => void;
  logout: (shouldReload?: boolean) => void;
  updateUser: (data: Partial<User>) => void;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
  error: null,
  isAuthInitialized: false,
  isLoading: false,
  login: () => {},
  logout: () => {},
  updateUser: () => {},
});

import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { getApiUrl, getNextApiUrl } from '@/utils';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { Coupon } from '@/types/models';

const COUPON_OPTIONS: RequestOptions = { fields: ['code'] };

class CouponService {
  public getCoupon = async <T extends Coupon>(
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<string | null> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetCoupon, { ...COUPON_OPTIONS, filter: { user_id: { _eq: userId } } })
        : getNextApiUrl(NextApiEndpoint.GetCoupon, { ...COUPON_OPTIONS, filter: { user_id: { _eq: userId } } }),
    );

    return data.data[0]?.code || null;
  };
}

export default new CouponService();

export enum ActiveCampaignTagsIds {
  DataLemurRecentlyViewedCart = 15,
  DataLemurSuccessfulCancellation = 138,
}

export enum ActiveCampaignApiEndpoint {
  Contact = 'api/3/contacts',
  AddTag = 'api/3/contactTags',
  UpdateCustomField = 'api/3/fieldValues',
}

export enum ActiveCampaignCustomFieldIds {
  // To address or update custom field of Active Campaign user we use corresponding id to that field
  // Can be found in response of GET api/3/fields request (check inside network tab at "/app/settings/fields/contacts" route)
  LastEngagementDate = '53',
}

import Cookies from 'js-cookie';

import { UTM_PARAMS_KEY } from '@/constants';
import { NextApiRequest } from 'next';
import { UTMParams } from '@/types/models';
import { convertKeysToCamelCase } from '@/utils/convertKeysToCamelCase';
import { AuthenticatedRequest } from '@/hofs';

export const setUTMParamsCookie = (utmParams: Record<string, string>) => {
  if (typeof document !== 'undefined') {
    return Cookies.set(UTM_PARAMS_KEY, JSON.stringify(utmParams));
  }
};

export const getUTMParamsCookie = (req: NextApiRequest | AuthenticatedRequest): Partial<UTMParams> => {
  const utmParams = req.cookies.utm_params || '{}';

  return utmParams ? convertKeysToCamelCase(JSON.parse(utmParams)) : {};
};

import { QuestionCategory } from '@/constants';

const BASE_QUESTIONS_FILTER = {
  active: { _eq: true },
  access_groups: { _null: true },
};

const getQuestionsFilterOptions = (questionsCategory?: QuestionCategory) =>
  questionsCategory
    ? {
        ...BASE_QUESTIONS_FILTER,
        category: { _eq: questionsCategory },
      }
    : BASE_QUESTIONS_FILTER;

export const getQuestionsOptions = (questionsCategory?: QuestionCategory) => ({
  filter: getQuestionsFilterOptions(questionsCategory),
  sort: 'sort',
});

// Chakra uses the @media(min-width) media query to ensure your interfaces are mobile-first.

export const breakpoints = {
  extraLarge: '1921px',
  xl: '1200px',
  lg: '960px',
  md: '768px',
  sm: '480px',
  base: '100px',
};

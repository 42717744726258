import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    /* doesn't apply for some reason, so its applied directly through the props in
     [MenuFilterCategory, MenuFilterDifficulty, MenuFilterStatus, NewCommentInput MenuButton components] */
    // bg: 'gray.200',
    // minW: '126px',
    // p: '6px',
    // _hover: {
    //   bg: 'gray.300',
    //   _active: {
    //     bg: 'gray.400',
    //   },
    // },
  },
  item: {
    _hover: {
      bg: 'gray.300',
      _active: {
        bg: 'gray.400',
      },
    },
  },
});

export const Menu = defineMultiStyleConfig({ baseStyle });

import { EffectCallback, useEffect, useRef } from 'react';

export const useEffectOnceWhen = (callback: EffectCallback, conditionRaw: any) => {
  const isExecutedRef = useRef<boolean>(false);
  const condition = Boolean(conditionRaw);

  useEffect(() => {
    if (condition && !isExecutedRef.current) {
      isExecutedRef.current = true;
      callback();
    }
  }, [condition, callback]);
};

import mysql, { Connection } from 'mysql2/promise';
import {
  CommonProcessedRuntimeResponse,
  ErrorMessageProcessorOptions,
  ForbiddenTablesSelectingQuery,
  MutatingQuery,
  MySQLQueryResult,
  SelectingQuery,
} from '@/types';
import {
  IS_SERVER,
  MY_SQL_RUNTIME_DATABASE,
  MY_SQL_RUNTIME_HOST,
  MY_SQL_RUNTIME_PASSWORD,
  MY_SQL_RUNTIME_PORT,
  MY_SQL_RUNTIME_USER,
  SupportedRuntime,
} from '@/constants';
import { IRuntime } from './IRuntime';

export class MySQLRuntime extends IRuntime<SupportedRuntime.MySQL> {
  protected runtime!: Connection;

  async init(): Promise<void> {
    try {
      if (!IS_SERVER) return;
      this.runtime = await mysql.createConnection({
        host: MY_SQL_RUNTIME_HOST,
        port: MY_SQL_RUNTIME_PORT,
        user: MY_SQL_RUNTIME_USER,
        password: MY_SQL_RUNTIME_PASSWORD,
        database: MY_SQL_RUNTIME_DATABASE,
      });
    } catch (error) {
      console.error(error);
    }
  }

  constructor() {
    super();
  }

  async execute(query: string): Promise<MySQLQueryResult> {
    const result = await this.runtime.query({
      sql: query,
      rowsAsArray: true,
    });
    return result;
  }

  interpolateSystemRelationNamesIntoQuery(query: string, servedTables: string[], postfix: string | number): string {
    let processedQuery = query;

    servedTables.map(
      (tName) =>
        (processedQuery = processedQuery.replace(new RegExp(`\\b${tName}\\b`, 'gim'), `${tName}_${String(postfix)}`)),
    );

    return processedQuery;
  }

  processErrorMessage(errorMsg?: string, options: ErrorMessageProcessorOptions = {}): string {
    if (!errorMsg) return '';

    let processedMessage: string = errorMsg;
    switch (true) {
      case ['Table', "doesn't exist"].every((keyStr) => errorMsg.includes(keyStr)) && !!options.servedTables:
        processedMessage = `[mysql]: Specified relation(-s) does not exist. Available relation names: [${options.servedTables?.join(
          ', ',
        )}]`;
        break;
      case ['Unknown', 'column'].every((keyStr) => errorMsg.includes(keyStr)):
        processedMessage = '[mysql]: Specified column(-s) does not exist. \n\n';
        break;
    }

    return processedMessage;
  }

  processQueryResult(queryResult: MySQLQueryResult): CommonProcessedRuntimeResponse {
    const processedQueryResult = this.processQueryResultFields(queryResult).map((record) =>
      this.excludeSystemFields(record),
    );
    return processedQueryResult;
  }

  isMutatingQuery(maybeMutatingQuery: string): maybeMutatingQuery is MutatingQuery {
    return [
      'CREATE',
      'DROP',
      'ALTER',
      'TRUNCATE',
      'COMMENT',
      'RENAME',
      'INSERT',
      'UPDATE',
      'DELETE',
      'LOCK',
      'CALL',
      'EXPLAIN',
      'GRANT',
      'REVOKE',
      'COMMIT',
      'ROLLBACK',
      'SAVEPOINT',
    ].some((item) => maybeMutatingQuery.toUpperCase().includes(item));
  }

  isForbiddenTablesSelectingQuery(
    maybeForbiddenTablesSelectingQuery: string,
  ): maybeForbiddenTablesSelectingQuery is ForbiddenTablesSelectingQuery {
    return (
      ['information_schema.tables'].some((table) =>
        maybeForbiddenTablesSelectingQuery.toLocaleLowerCase().includes(table),
      ) ||
      [/INNODB_[a-zA-Z0-9_]+/].some((template) => template.test(maybeForbiddenTablesSelectingQuery.toLocaleLowerCase()))
    );
  }

  isSelectingQuery(maybeSelectingQuery: string): maybeSelectingQuery is SelectingQuery {
    return maybeSelectingQuery.toUpperCase().includes('SELECT');
  }

  protected processQueryResultFields([queryResult, fields]: MySQLQueryResult): CommonProcessedRuntimeResponse {
    const processedQueryResultFields = (fields || [])
      .map((field) => field.name)
      .map((fieldName, index, self) =>
        self.slice(0, index).includes(fieldName) ? `${fieldName}__dup${index}__` : fieldName,
      );

    return ((queryResult as Omit<mysql.QueryResult, 'OxPacket'>[]) || []).map((row) =>
      Object.fromEntries(Object.entries(row).map(([key, value]) => [processedQueryResultFields[Number(key)], value])),
    );
  }

  protected excludeSystemFields(sourceObject: Record<string, any>): Record<string, any> {
    for (const key in sourceObject) {
      if (/\b(id|created_at|updated_at|date_created|date_updated)(__dup\d+__)?\b/.test(key)) delete sourceObject[key];
    }

    return sourceObject;
  }
}

export const mySQLRuntime = new MySQLRuntime();

import { QuestionCategory } from '@/constants';
import { DEFAULT_TEXT_EDITOR_VALUE } from '@/constants/pages';

const DEFAULT_SQL_COMMENT_TEXT = `Type your comment here...

\`\`\`sql
SELECT * FROM users;
\`\`\`
`;

export const getDefaultCommentText = (questionCategory: QuestionCategory) => {
  if (questionCategory === QuestionCategory.SQL || questionCategory === QuestionCategory.TheOfficeSQL) {
    return DEFAULT_SQL_COMMENT_TEXT;
  }

  return DEFAULT_TEXT_EDITOR_VALUE;
};

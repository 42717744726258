export enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  UnprocessableEntity = 422,

  InternalServerError = 500,

  NoContent = 204,
  Success = 200,
}

import { AccessGroup } from '@/constants';

export const addAccessGroup = (accessGroups: AccessGroup[] | null, newGroup: AccessGroup): AccessGroup[] => {
  if (!accessGroups) {
    return [newGroup];
  }

  if (accessGroups.includes(newGroup)) {
    return accessGroups;
  }

  return [...accessGroups, newGroup];
};

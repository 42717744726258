import { SupportedRuntime } from '@/constants';
import { IRuntime, mySQLRuntime, pgRuntime } from '@/services/runtimes';
import { Email, ForbiddenTablesSelectingQuery, MutatingQuery, SelectingQuery } from '@/types';

//FIXME: could be modified/improved soon
export const isMutatingQuery = (maybeMutatingQuery: any): maybeMutatingQuery is MutatingQuery =>
  !!maybeMutatingQuery &&
  typeof maybeMutatingQuery === 'string' &&
  ['INSERT', 'UPSERT', 'UPDATE', 'DELETE', 'CREATE', 'DROP', 'COPY'].some((item) =>
    maybeMutatingQuery.toUpperCase().includes(item),
  );

export const isForbiddenTablesSelectingQuery = (
  maybeForbiddenTablesSelectingQuery: string,
): maybeForbiddenTablesSelectingQuery is ForbiddenTablesSelectingQuery =>
  (typeof maybeForbiddenTablesSelectingQuery === 'string' &&
    ['information_schema.tables', 'tables'].some((table) =>
      maybeForbiddenTablesSelectingQuery.toLocaleLowerCase().includes(table),
    )) ||
  [/directus_[a-zA-Z0-9_]+/, /pg_[a-zA-Z0-9_]+/].some((template) =>
    template.test(maybeForbiddenTablesSelectingQuery.toLocaleLowerCase()),
  );

export const isSelectingQuery = (maybeSelectingQuery: any): maybeSelectingQuery is SelectingQuery =>
  !!maybeSelectingQuery &&
  typeof maybeSelectingQuery === 'string' &&
  maybeSelectingQuery.toUpperCase().includes('SELECT');

export const isObject = (maybeObject: any): maybeObject is Record<string, any> =>
  maybeObject != null && typeof maybeObject === 'object';

export const isEmail = (maybeEmail: any): maybeEmail is Email =>
  typeof maybeEmail === 'string' &&
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    maybeEmail,
  );

export const RUNTIME_MAP: Record<SupportedRuntime, IRuntime<SupportedRuntime.Postgres | SupportedRuntime.MySQL>> = {
  [SupportedRuntime.Postgres]: pgRuntime,
  [SupportedRuntime.MySQL]: mySQLRuntime,
};

export const COMMENT_FIELDS: string[] = [
  'id',
  'user_id',
  'body',
  'parent_id',
  'created_at',
  'updated_at',
  'deleted_at',
  'upvotes',
  'downvotes',
  'quality_score',
  'question_id',
];

export enum Operations {
  Watch,
  Run,
  Submit,
  DisabledRun,
  DisabledAll,
}

export const DEFAULT_TEXT_EDITOR_VALUE = `Type your answer here....

You can also write LaTex math formulas by using the $ symbol.

$P(A|B) = \\sigma + z \\sqrt{\\frac{p(1-p)}{n}} $`;

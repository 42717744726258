import { BillingPlanTitle, BillingPlanType } from '@/constants';

export const getBillingPlanTitle = (type: BillingPlanType): BillingPlanTitle | string => {
  switch (type) {
    case BillingPlanType.Free:
      return BillingPlanTitle.Free;
    case BillingPlanType.Monthly:
      return BillingPlanTitle.Monthly;
    case BillingPlanType.Yearly:
      return BillingPlanTitle.Yearly;
    case BillingPlanType.Lifetime:
      return BillingPlanTitle.Lifetime;
    default:
      return '';
  }
};

import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { CamelToSnakeCaseNested } from '@/types';
import { convertKeysToCamelCase, getApiUrl, getNextApiUrl } from '@/utils';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { CreateSubscriptionValues, PayAndSubscribeResponse, PayAndSubscribeValues } from '@/types/pages/checkout';
import { GAnalyticsClientData, Subscription } from '@/types/models';
import { Logger } from '@/services/index';

const SUBSCRIPTION_OPTIONS: RequestOptions = {
  fields: ['id', 'period_ends_at', 'subscription_type', 'amount', 'status', 'cancel_at', 'next_payment_attempt'],
};
const LAST_SUBSCRIPTION_OPTIONS: RequestOptions = {
  ...SUBSCRIPTION_OPTIONS,
  sort: '-created_at',
  limit: 1,
};

class SubscriptionService {
  public payAndSubscribe = async <T extends PayAndSubscribeResponse>(
    values: PayAndSubscribeValues & { gAnalyticsClientData: Partial<GAnalyticsClientData>; userAgent: string },
    options?: RequestOptions,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getNextApiUrl(NextApiEndpoint.Subscribe, options),
      values,
    );

    return convertKeysToCamelCase<T>(data);
  };

  public createSubscription = async <T extends Subscription>(
    value: CamelToSnakeCaseNested<CreateSubscriptionValues>,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.CreateSubscription, SUBSCRIPTION_OPTIONS)
        : getNextApiUrl(NextApiEndpoint.CreateSubscription, SUBSCRIPTION_OPTIONS),
      value,
    );

    return convertKeysToCamelCase<T>(data.data);
  };

  public getSubscriptionById = async <T extends Partial<Subscription> = Subscription>(
    subscriptionId: string,
    options?: RequestOptions,
  ): Promise<T | null> => {
    try {
      const { data } = await Api.get<ApiResponseData<CamelToSnakeCaseNested<T>>>(
        getApiUrl(ApiEndpoint.GetSubscriptionById.replace('{subscriptionId}', subscriptionId), options),
      );

      return convertKeysToCamelCase<T>(data.data);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public getLastSubscription = async <T extends Partial<Subscription> = Subscription>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetSubscriptions, { ...LAST_SUBSCRIPTION_OPTIONS, ...options })
        : getNextApiUrl(NextApiEndpoint.GetSubscriptions, { ...LAST_SUBSCRIPTION_OPTIONS, ...options }),
    );

    // @ts-ignore
    return convertKeysToCamelCase<T>(data.data);
  };

  public checkSubscription = async <T extends CreateSubscriptionValues>(
    values: Pick<PayAndSubscribeResponse, 'id' | 'isOneTimePayment'> & {
      gAnalyticsClientData: Partial<GAnalyticsClientData>;
      userAgent: string;
    },
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getNextApiUrl(NextApiEndpoint.CheckSubscription),
      values,
    );

    return convertKeysToCamelCase<T>(data);
  };

  public updateSubscription = async <T extends Partial<Subscription>>(
    subscriptionId: string,
    newSubscriptionData: CamelToSnakeCaseNested<Partial<Subscription>>,
    options?: RequestOptions,
  ): Promise<T> => {
    const { data } = await Api.patch<ApiResponseData<CamelToSnakeCaseNested<T>>>(
      getApiUrl(ApiEndpoint.UpdateSubscription.replace('{subscriptionId}', subscriptionId), options),
      { ...newSubscriptionData },
    );

    return convertKeysToCamelCase<T>(data);
  };
}

export default new SubscriptionService();

import { Lesson } from '@/types/models';
import { LessonCategory } from '@/constants';

export const filterLessonsByCategory = <T extends Pick<Lesson, 'category' | 'lessonNumber'>>(
  lessons: T[],
  category: LessonCategory,
): T[] =>
  lessons
    .filter((lesson) => lesson.category === category)
    .sort((lesson, nextLessons) => lesson.lessonNumber - nextLessons.lessonNumber);

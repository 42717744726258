import { Question } from '@/types/models';

export const POSTGRES_RUNTIME_CONNECTION_STRING = process.env.POSTGRES_RUNTIME_CONNECTION_STRING as string;

export const MY_SQL_RUNTIME_USER = process.env.MY_SQL_RUNTIME_USER as string;
export const MY_SQL_RUNTIME_PORT = +((process.env.MY_SQL_RUNTIME_PORT as string) || 3306);
export const MY_SQL_RUNTIME_PASSWORD = process.env.MY_SQL_RUNTIME_PASSWORD as string;
export const MY_SQL_RUNTIME_HOST = process.env.MY_SQL_RUNTIME_HOST as string;
export const MY_SQL_RUNTIME_DATABASE = process.env.MY_SQL_RUNTIME_DATABASE as string;

export enum SupportedRuntime {
  Postgres = 'postgres',
  MySQL = 'mysql',
  // Python = 'python',
}

export const MAP_RUNTIMES_TO_TEXT: Record<SupportedRuntime, string> = {
  [SupportedRuntime.Postgres]: 'PostgreSQL 14',
  [SupportedRuntime.MySQL]: 'MySQL',
  // [SupportedRuntimes.Python]: `Python`,
};

export const MAP_RUNTIME_TO_PROPER_QUERY_FIELD_NAME: Record<
  SupportedRuntime,
  keyof Pick<Question, 'properQuery' | 'mysqlProperQuery'>
> = {
  [SupportedRuntime.Postgres]: 'properQuery',
  [SupportedRuntime.MySQL]: 'mysqlProperQuery',
};

// TODO:
export const MAP_RUNTIME_TO_ACE_EDITOR_MODE: Record<SupportedRuntime, string> = {
  [SupportedRuntime.Postgres]: 'pgsql',
  [SupportedRuntime.MySQL]: 'mysql',
};

export enum QuestionCategory {
  SQL = 'SQL',
  DatabaseDesign = 'DatabaseDesign',
  Probability = 'Probability',
  Statistics = 'Statistics',
  MachineLearning = 'MachineLearning',
  Python = 'Python',
  TheOfficeSQL = 'TheOfficeSQL',
}

export const MAP_QUESTION_CATEGORY_TO_TEXT: Record<QuestionCategory, string> = {
  [QuestionCategory.SQL]: QuestionCategory.SQL,
  [QuestionCategory.DatabaseDesign]: `Database Design`,
  [QuestionCategory.Probability]: QuestionCategory.Probability,
  [QuestionCategory.Statistics]: QuestionCategory.Statistics,
  [QuestionCategory.MachineLearning]: 'Machine Learning',
  [QuestionCategory.Python]: QuestionCategory.Python,
  [QuestionCategory.TheOfficeSQL]: 'The Office SQL',
};

import { Routes, QuestionCategory } from '@/constants';

export const getQuestionsRoute = (category?: QuestionCategory): Routes => {
  switch (category) {
    case QuestionCategory.SQL:
      return Routes.SQLQuestions;
    case QuestionCategory.DatabaseDesign:
      return Routes.DatabaseDesignQuestions;
    case QuestionCategory.Probability:
      return Routes.ProbabilityQuestions;
    case QuestionCategory.Statistics:
      return Routes.StatisticsQuestions;
    case QuestionCategory.MachineLearning:
      return Routes.MachineLearningQuestions;
    case QuestionCategory.TheOfficeSQL:
      return Routes.OfficeSQlQuestions;
    default:
      return Routes.Questions;
  }
};

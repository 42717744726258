export enum SubscriptionStatus {
  Active = 'active',
  PastDue = 'past_due',
  Cancelled = 'cancelled',
}

export const MAP_SUBSCRIPTION_STATUS_TO_TEXT: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.Active]: `${SubscriptionStatus.Active.charAt(0)}${SubscriptionStatus.Active.slice(1)}`,
  [SubscriptionStatus.PastDue]: `${SubscriptionStatus.PastDue.charAt(0)}${SubscriptionStatus.PastDue.slice(1)
    .split('_')
    .join(' ')}`,
  [SubscriptionStatus.Cancelled]: `${SubscriptionStatus.Cancelled.charAt(0)}${SubscriptionStatus.Cancelled.slice(1)}`,
};

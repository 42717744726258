import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

import { BE_JWT_TOKEN, BE_URL, IS_SERVER } from '@/constants/configs';

interface RequestConfig extends AxiosRequestConfig {
  isAuthorizedRequest?: boolean;
  isAdminRequest?: boolean;
  forceTokenUpdate?: boolean;
}

type SupportedMethods = 'get' | 'post' | 'put' | 'patch' | 'delete';

class Api {
  private token: string = '';

  private createApiEngine = () => {
    // NOTE: We need to apply case middleware only on the server side to transform camelCase objects
    // from the frontend to snake_case, which is accepted by Directus API.
    const {
      get,
      post,
      put,
      patch,
      delete: deleteRequest,
    } = IS_SERVER ? applyCaseMiddleware(axios.create()) : axios.create();

    return {
      get,
      post,
      put,
      patch,
      delete: deleteRequest,
    };
  };

  private apiEngine = this.createApiEngine();

  private createEndpointWithoutData =
    (method: SupportedMethods) =>
    async <T>(url: string, config?: RequestConfig): Promise<AxiosResponse<T>> =>
      this.apiEngine[method]<T>(
        url,
        // Adding admin token client-side requests
        this.expandConfig(url.includes(BE_URL) ? { ...config, isAdminRequest: true } : config),
      );

  private createEndpointWithData =
    (method: SupportedMethods) =>
    async <T>(url: string, data?: unknown, config?: RequestConfig): Promise<AxiosResponse<T>> =>
      this.apiEngine[method]<T>(
        url,
        data,
        // Same idea as in comment above
        this.expandConfig(url.includes(BE_URL) ? { ...config, isAdminRequest: true } : config),
      );

  public get = this.createEndpointWithoutData('get');
  public post = this.createEndpointWithData('post');
  public put = this.createEndpointWithData('put');
  public patch = this.createEndpointWithData('patch');
  public delete = this.createEndpointWithoutData('delete');

  private getHeaders = ({
    isAuthorizedRequest = true,
    isAdminRequest = false,
    forceTokenUpdate = false,
    headers = {},
  }: RequestConfig = {}): Pick<RequestConfig, 'headers'> => {
    const newHeaders = { ...headers };

    if (isAuthorizedRequest && (forceTokenUpdate || !newHeaders.Authorization)) {
      const token = isAdminRequest ? BE_JWT_TOKEN : this.token;

      if (token) {
        newHeaders.Authorization = `Bearer ${token}`;
      }
    }

    return { headers: newHeaders };
  };

  private expandConfig = (config: RequestConfig = {}): RequestConfig => ({
    ...config,
    ...this.getHeaders(config),
  });
}

const api = new Api();

export default api;

export type { CancelToken } from 'axios';

import { stringify } from 'query-string';
import { REFERRAL_CODE_KEY } from '@/constants';

enum ShareType {
  Twitter,
  LinkedIn,
}

interface Options {
  url: string;
  text?: string;
  hashtags?: string[];
  referralCode?: string;
}

interface Return {
  twitterUrl: string;
  linkedInUrl: string;
}

const SHARE_TYPE_TO_URL_MAP: Record<ShareType, string> = {
  [ShareType.Twitter]: 'https://twitter.com/intent/tweet',
  [ShareType.LinkedIn]: 'https://www.linkedin.com/sharing/share-offsite',
};

const getShareQuery = (shareType: ShareType, options: Options): string => {
  switch (shareType) {
    case ShareType.Twitter:
      return stringify(
        {
          text: options.text,
          url: options.url,
          hashtags: options.hashtags && options.hashtags.join(','),
        },
        { skipNull: true },
      );
    case ShareType.LinkedIn:
      return stringify({ url: options.url });
    default:
      return '';
  }
};

const parseReferralQuery = (isMobile?: boolean) => (isMobile ? `?${REFERRAL_CODE_KEY}=` : `%3F${REFERRAL_CODE_KEY}%3D`);

export const getReferralQuery = (referralCode?: string, isMobile?: boolean) =>
  referralCode ? `${parseReferralQuery(isMobile)}${referralCode}` : '';

const getSharingLink = (shareType: ShareType, options: Options) => {
  return `${SHARE_TYPE_TO_URL_MAP[shareType]}?${getShareQuery(shareType, options)}${getReferralQuery(
    options.referralCode,
  )}`;
};

export const getSharingLinks = (options: Options): Return => ({
  twitterUrl: getSharingLink(ShareType.Twitter, options),
  linkedInUrl: getSharingLink(ShareType.LinkedIn, options),
});

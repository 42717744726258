export const mockMarkdown = `
Table: \`\`\`Employee\`\`\`
\`\`\`
+-------------+------+
| Column Name | Type |
+-------------+------+
| id          | int  |
| salary      | int  |
+-------------+------+
id is the primary key column for this table.
Each row of this table contains information about the salary of an employee.
\`\`\`
Find all valid combinations of k numbers that sum up to n such that the following conditions are true:

- Only numbers 1 through 9 are used.
- Each number is used at most ***once***
- Each number is used at most *once*

Return a list of all possible valid combinations. The list must not contain the same combination twice, and the combinations may be returned in any order.
&nbsp;

![ alt text for screen readers](https://assets.leetcode.com/uploads/2020/10/02/addtwonumber1.jpg "Text to show on mouseover") 
Write an SQL query to report the second highest salary from the Employee table. If there is no second highest salary, the query should report null.

The query result format is in the following example.

&nbsp;

Example 1:
\`\`\`
Input: 
Employee table:
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
| 2  | 200    |
| 3  | 300    |
+----+--------+
Output: 
+---------------------+
| SecondHighestSalary |
+---------------------+
| 200                 |
+---------------------+
\`\`\`
Example 2:
\`\`\`
Input: 
Employee table:
+----+--------+
| id | salary |
+----+--------+
| 1  | 100    |
+----+--------+
Output: 
+---------------------+
| SecondHighestSalary |
+---------------------+
| null                |
+---------------------+
\`\`\`
`;

export const mockCommentMarkdown = `\`\`\`
+-------------+------+
| Column Name | Type |
+-------------+------+
| id          | int  |
| salary      | int  |
+-------------+------+
id is the primary key column for this table.
Each row of this table contains information about the salary of an employee.
\`\`\`
And I think that's the solution`;

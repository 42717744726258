import { Tag } from '@/types/models';
import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { getApiUrl } from '@/utils';
import { ApiEndpoint } from '@/constants';

class TagService {
  public getTags = async <T extends Tag>(options?: RequestOptions): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetTags, options));

    return data.data;
  };
}

export default new TagService();

import { Lesson } from '@/types/models';
import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { getApiUrl } from '@/utils';
import { ApiEndpoint } from '@/constants';

class Tutorial {
  public getLessons = async <T extends Partial<Lesson>>(options?: RequestOptions): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetSqlLessons, options));

    return data.data;
  };

  public getLessonBySlug = async <T extends Partial<Lesson> = Lesson>(
    slug: string,
    options: RequestOptions = {},
  ): Promise<T | null> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        slug: { _eq: slug },
      },
    };
    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetSqlLessons, finalOptions));

    return data.data[0];
  };
}

const tutorial = new Tutorial();

export default tutorial;

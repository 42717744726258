import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { SnakeToCamelCaseNested } from '@/types';
import { ApiResponseData, RequestOptions } from '@/types/api';
import { CreateSubmissionOptions } from '@/types/api';
import { Submission } from '@/types/models';
import { getApiUrl, getNextApiUrl } from '@/utils';
import Api from './Api';

/**
 * Unlimit the number of received submissions. Implemented due to the default Directus limitation of getting 100 items.
 * Currently used by /questions and /questions/[questionSlug]
 */
const SUBMISSION_OPTIONS: RequestOptions = {
  limit: -1,
};

class SubmissionService {
  public getSubmissions = async <T extends Partial<Submission> = Submission>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetSubmissions, { ...SUBMISSION_OPTIONS, ...options })
        : getNextApiUrl(NextApiEndpoint.GetSubmissions, { ...SUBMISSION_OPTIONS, ...options }),
    );

    return data.data;
  };

  public createSubmission = async <T extends Partial<Submission> = Submission>(
    { query, questionId, status, errorMsg, language }: SnakeToCamelCaseNested<CreateSubmissionOptions>,
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
    userId?: string,
  ): Promise<T> => {
    const { data } = await Api.post<ApiResponseData<T>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.CreateSubmission, options)
        : getNextApiUrl(NextApiEndpoint.CreateSubmission, options),
      {
        user_id: userId,
        question_id: questionId,
        query,
        status,
        error_msg: errorMsg,
        query_language: language,
      },
    );

    return data.data;
  };

  public getLatestSubmissionForEachQuestion = async <T extends Partial<Submission> = Submission>(
    options?: RequestOptions,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      getNextApiUrl(NextApiEndpoint.LatestSubmissions, { ...options }),
    );

    return data.data;
  };
}

const submissionService = new SubmissionService();

export default submissionService;

import { Hint } from '@/types/models';
import { ApiResponseData, RequestOptions } from '@/types/api';
import Api from '@/services/Api';
import { getApiUrl, getNextApiUrl } from '@/utils';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';

class HintService {
  public getHints = async <T extends Partial<Hint> = Hint>(
    options?: RequestOptions,
    isRequestFromAPIRoute: boolean = false,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      isRequestFromAPIRoute
        ? getApiUrl(ApiEndpoint.GetHints, options)
        : getNextApiUrl(NextApiEndpoint.GetHints, options),
    );

    return data.data;
  };
}

export default new HintService();

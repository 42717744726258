import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const stripedVariant = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'tableOddRows', // for some reason gray.value doesn't set the right color
        },
      },
    },
  };
});

const cleanVariant = definePartsStyle(() => {
  return {
    thead: {
      th: {
        padding: '6px 12px',
        fontWeight: 500,
        color: 'black.200',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    tbody: {
      td: {
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: '20px',
        borderTop: '1px solid',
        borderColor: 'gray.300',
      },
    },
  };
});

const primary = definePartsStyle(() => {
  return {
    table: {
      backgroundColor: 'white',
    },
    thead: {
      th: {
        textTransform: 'none',
        padding: '8px 16px',
        fontWeight: 500,
        color: 'black.500',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    tbody: {
      td: {
        height: '50px',
        padding: '0px 16px',
        fontSize: '14px',
        lineHeight: '20px',
        borderTop: '1px solid',
        borderColor: 'gray.300',
      },
      tr: {
        cursor: 'pointer',
        _hover: {
          bg: 'gray.200',
        },
      },
    },
  };
});

const variants = {
  stripedVariant,
  clean: cleanVariant,
  primary,
};

export const Table = defineMultiStyleConfig({ variants });

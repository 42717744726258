export enum LessonCategory {
  Basic = 'basic',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
}

export const MAP_LESSON_CATEGORY_TO_TEXT: Record<LessonCategory, string> = {
  [LessonCategory.Basic]: `${LessonCategory.Basic.charAt(0).toUpperCase()}${LessonCategory.Basic.slice(1)} SQL`,
  [LessonCategory.Intermediate]: `${LessonCategory.Intermediate.charAt(
    0,
  ).toUpperCase()}${LessonCategory.Intermediate.slice(1)} SQL`,
  [LessonCategory.Advanced]: `${LessonCategory.Advanced.charAt(0).toUpperCase()}${LessonCategory.Advanced.slice(
    1,
  )} SQL`,
};

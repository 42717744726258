import { QueryArrayResult } from 'pg';

import { VOTES_VALUE_DEFAULT } from '@/constants/defaultValues';
import { Comment } from '@/types/models';
import { isObject } from './typeGuards';

const defaultVoteValueSetter = (votesValue: number | null): number => (votesValue ? votesValue : VOTES_VALUE_DEFAULT);

export const compareComments = (comment: Comment, nextComment: Comment) =>
  defaultVoteValueSetter(nextComment.upvotes) -
  defaultVoteValueSetter(nextComment.downvotes) +
  defaultVoteValueSetter(nextComment.qualityScore) -
  (defaultVoteValueSetter(comment.upvotes) -
    defaultVoteValueSetter(comment.downvotes) +
    defaultVoteValueSetter(comment.qualityScore));

export const deeplyEquate = (objectA: any, objectB: any): boolean => {
  const keysA = Object.keys(objectA);
  const keysB = Object.keys(objectB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    const valA = objectA[keysA[i]];
    const valB = objectB[keysA[i]] || objectB[keysB[i]];
    const areObjects = isObject(valA) && isObject(valB);

    if ((areObjects && !deeplyEquate(valA, valB)) || (!areObjects && valA !== valB && String(valA) !== String(valB)))
      return false;
  }

  return true;
};

export const excludeSystemFields = (sourceObject: Record<string, any>): Record<string, any> => {
  for (const key in sourceObject) {
    if (/\b(id|created_at|updated_at|date_created|date_updated)(__dup\d+__)?\b/.test(key)) delete sourceObject[key];
  }

  return sourceObject;
};

export const interpolateSystemRelationNamesIntoQuery = (
  query: string,
  servedTables: string[],
  postfix: string | number,
): string => {
  let processedQuery = query;

  servedTables.map(
    (tName) =>
      (processedQuery = processedQuery.replace(new RegExp(`\\b${tName}\\b`, 'gim'), `\"${tName}_${String(postfix)}\"`)),
  );

  return processedQuery;
};

export const processQueryResult = (queryResult: QueryArrayResult<Record<string, any>[]>): Record<string, any>[] => {
  const processedQueryResultFields = (queryResult.fields || [])
    .map((field) => field.name)
    .map((fieldName, index, self) =>
      self.slice(0, index).includes(fieldName) ? `${fieldName}__dup${index}__` : fieldName,
    );

  return (queryResult.rows || []).map((row) =>
    Object.fromEntries(Object.entries(row).map(([key, value]) => [processedQueryResultFields[Number(key)], value])),
  );
};

export const getErrorLine = (query: string, position: number): number | null => {
  if (!query || !position) return null;

  return (query.substring(0, position).match(/\n/g) || []).length + 1;
};

export const includeErrorLineToMessage = (errorMsg: string, query: string, position?: number) =>
  `${errorMsg}${query && position ? ` (LINE: ${getErrorLine(query, position)}` : ''})`;

export const arrayUniqueByKey = <T, K extends keyof T>(list: T[], key: K) => {
  return [...new Map(list.map((item) => [item[key], item])).values()];
};

export const excludeCommentsFromSQLQuery = (query: string) =>
  query
    .replace(/\/\*[\s\S]*?\*\//g, '')
    .replace(/--.*?\n/g, '')
    .replace(/\s+--.*/, '');

import { useRouter } from 'next/router';

import { Difficulty, QuestionCategory, QuestionStatusFilter } from '@/constants';

export const useQuestionsFilterQuery = () => {
  const { push, query } = useRouter();

  const setFilterQuery = async (key: string, value: QuestionCategory | Difficulty | QuestionStatusFilter | null) => {
    if (!value) {
      delete query[key];
      await push({ query: { ...query } }, undefined, { shallow: true });

      return;
    }

    await push({ query: { ...query, [key]: value } }, undefined, { shallow: true });
  };

  return { setFilterQuery };
};
